import React, { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, InputLabel, FormControl, TablePagination } from '@mui/material';
import { getAdressesFacturation, createAdresseFacturation, updateAdresseFacturation, deleteAdresseFacturation } from '../models/adresseFacturation';
import { getGroups } from '../models/group';

const AdressesFacturationPage = () => {
  const [adresses, setAdresses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [newAdresse, setNewAdresse] = useState({ nom: '', adresse_ligne1: '', adresse_ligne2: '', ville: '', code_postal: '', pays: '', telephone: '', id_groupe: '' });
  const [editAdresse, setEditAdresse] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const user = JSON.parse(localStorage.getItem('user'));

  const fetchAdresses = useCallback(async () => {
    const data = await getAdressesFacturation(user.id_societe);
    setAdresses(data);
  }, [user.id_societe]);

  const fetchGroups = useCallback(async () => {
    const data = await getGroups();
    setGroups(data);
  }, []);

  useEffect(() => {
    fetchAdresses();
    fetchGroups();
  }, [fetchAdresses, fetchGroups]);

  const handleCreate = async () => {
    const adresseToCreate = { ...newAdresse, id_societe: user.id_societe };
    if (adresseToCreate.id_groupe === '') {
      adresseToCreate.id_groupe = null;
    }
    await createAdresseFacturation(adresseToCreate);
    setNewAdresse({ nom: '', adresse_ligne1: '', adresse_ligne2: '', ville: '', code_postal: '', pays: '', telephone: '', id_groupe: '' });
    fetchAdresses();
  };

  const handleUpdate = async (id) => {
    const adresseToUpdate = { ...editAdresse, id_societe: user.id_societe };
    if (adresseToUpdate.id_groupe === '') {
      adresseToUpdate.id_groupe = null;
    }
    await updateAdresseFacturation(id, adresseToUpdate);
    setEditAdresse(null);
    fetchAdresses();
  };

  const handleDelete = async (id) => {
    await deleteAdresseFacturation(id);
    fetchAdresses();
  };

  const handleNewAdresseChange = (e) => {
    const { name, value } = e.target;
    setNewAdresse((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditAdresseChange = (e) => {
    const { name, value } = e.target;
    setEditAdresse((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Adresses de Facturation</Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextField
          label="Nom"
          name="nom"
          value={newAdresse.nom}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <TextField
          label="Adresse Ligne 1"
          name="adresse_ligne1"
          value={newAdresse.adresse_ligne1}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <TextField
          label="Adresse Ligne 2"
          name="adresse_ligne2"
          value={newAdresse.adresse_ligne2}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <TextField
          label="Ville"
          name="ville"
          value={newAdresse.ville}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <TextField
          label="Code Postal"
          name="code_postal"
          value={newAdresse.code_postal}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <TextField
          label="Pays"
          name="pays"
          value={newAdresse.pays}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <TextField
          label="Téléphone"
          name="telephone"
          value={newAdresse.telephone}
          onChange={handleNewAdresseChange}
          margin="normal"
          style={{ width: '50%' }}
        />
        <FormControl margin="normal" style={{ width: '50%' }}>
          <InputLabel>Groupe</InputLabel>
          <Select
            name="id_groupe"
            value={newAdresse.id_groupe}
            onChange={handleNewAdresseChange}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.nom}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Créer
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
             
              <TableCell>Nom</TableCell>
              <TableCell>Adresse Ligne 1</TableCell>
              <TableCell>Adresse Ligne 2</TableCell>
              <TableCell>Ville</TableCell>
              <TableCell>Code Postal</TableCell>
              <TableCell>Pays</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Groupe</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adresses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((adresse) => (
              <TableRow key={adresse.id}>
                
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="nom"
                      value={editAdresse.nom}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.nom
                  )}
                </TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="adresse_ligne1"
                      value={editAdresse.adresse_ligne1}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.adresse_ligne1
                  )}
                </TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="adresse_ligne2"
                      value={editAdresse.adresse_ligne2}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.adresse_ligne2
                  )}
                </TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="ville"
                      value={editAdresse.ville}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.ville
                  )}
                </TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="code_postal"
                      value={editAdresse.code_postal}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.code_postal
                  )}
                </TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="pays"
                      value={editAdresse.pays}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.pays
                  )}
                </TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <TextField
                      name="telephone"
                      value={editAdresse.telephone}
                      onChange={handleEditAdresseChange}
                    />
                  ) : (
                    adresse.telephone
                  )}
                </TableCell>
                <TableCell>{adresse.groupe_nom}</TableCell>
                <TableCell>
                  {editAdresse && editAdresse.id === adresse.id ? (
                    <Button onClick={() => handleUpdate(adresse.id)}>Enregistrer</Button>
                  ) : (
                    <Button onClick={() => setEditAdresse(adresse)}>Modifier</Button>
                  )}
                  <Button onClick={() => handleDelete(adresse.id)}>Supprimer</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={adresses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
      <Typography variant="body1" align="center" margin="normal">
        Nombre total d'adresses de facturation : {adresses.length}
      </Typography>
    </Container>
  );
};

export default AdressesFacturationPage;

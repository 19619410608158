import axios from 'axios';

const API_URL = 'https://admin-gg-be.visamonde.com'; // Assure-toi que c'est la bonne URL

export const getGroups = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const idSociete = user ? user.id_societe : null;

  if (!idSociete) {
    throw new Error('ID de société non trouvé dans le localStorage');
  }

  const response = await axios.get(`${API_URL}/groupes`, {
    params: { id_societe: idSociete }
  });
  return response.data;
};

export const createGroup = async (group) => {
  const response = await axios.post(`${API_URL}/groupes`, group);
  return response.data;
};

export const updateGroup = async (id, group) => {
  const response = await axios.put(`${API_URL}/groupes/${id}`, group);
  return response.data;
};

export const deleteGroup = async (id) => {
  await axios.delete(`${API_URL}/groupes/${id}`);
};

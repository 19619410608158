import React, { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, TablePagination } from '@mui/material';
import { getDemandes } from '../models/demande'; // Import the model
import { getGroups } from '../models/group'; // Import the model for groups
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the visa icon
import { green } from '@mui/material/colors';

const DemandesPage = () => {
  const [demandes, setDemandes] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const user = JSON.parse(localStorage.getItem('user'));

  const fetchDemandes = useCallback(async () => {
    const data = await getDemandes(user.id_societe); // Use the model to fetch data
    setDemandes(data);
  }, [user.id_societe]);

  const fetchGroups = useCallback(async () => {
    const data = await getGroups();
    console.log(data);
    setGroups(data);
    if (user.droit === 'admin') {
      console.log(user);
      const userGroup = data.find(group => group.nom === user.groupe);
      if (userGroup) {
        setSelectedGroup(userGroup.id); // Set the selected group for admin
      } else {
        console.error("Group not found for the admin user");
      }
    }
  }, [user.droit, user.groupe]);

  useEffect(() => {
    fetchDemandes();
    fetchGroups();
  }, [fetchDemandes, fetchGroups]);

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredDemandes = selectedGroup
    ? demandes.filter(demande => demande.id_groupe === selectedGroup)
    : demandes;

  const totalDemandes = filteredDemandes.length;
  const totalVisasTraites = filteredDemandes.filter(demande => demande.etat_visa === 'traité').length;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Demandes</Typography>
      <Typography variant="subtitle1">
        Nombre total de demandes : {totalDemandes} | Nombre de visas traités : {totalVisasTraites}
      </Typography>
      {user.droit !== 'admin' && (
        <FormControl margin="normal" fullWidth>
          <InputLabel>Groupe</InputLabel>
          <Select
            value={selectedGroup}
            onChange={handleGroupChange}
          >
            <MenuItem value="">Tous les groupes</MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.nom}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Date de Demande</TableCell>
              <TableCell>Date de Départ</TableCell>
              <TableCell>Pays</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Prénom</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Visa</TableCell>
              <TableCell>Groupe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDemandes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((demande) => (
              <TableRow 
                key={demande.id} 
                style={{ backgroundColor: demande.etat_paiement === 'payé' ? green[100] : 'inherit' }}
              >
                <TableCell>{demande.id}</TableCell>
                <TableCell>{new Date(demande.date_creation).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(demande.date_depart).toLocaleDateString()}</TableCell>
                <TableCell>{demande.libelle_visa}</TableCell>
                <TableCell>{demande.prix_total} €</TableCell>
                <TableCell>{demande.nom}</TableCell>
                <TableCell>{demande.prenom}</TableCell>
                <TableCell>{demande.telephone}</TableCell>
                <TableCell>
                  {demande.etat_visa === 'traité' && <CheckCircleIcon color="primary" />}
                </TableCell>
                <TableCell>{demande.groupe_nom}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={filteredDemandes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Container>
  );
};

export default DemandesPage;

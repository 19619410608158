import axios from 'axios';

const API_URL = 'https://admin-gg-be.visamonde.com'; // Assure-toi que c'est la bonne URL

export const getUsers = async (id_societe) => {
  const response = await axios.get(`${API_URL}/utilisateurs/${id_societe}`);
  return response.data;
};

export const createUser = async (user) => {
  const response = await axios.post(`${API_URL}/utilisateurs`, user);
  return response.data;
};

export const updateUser = async (id, user) => {
  const response = await axios.put(`${API_URL}/utilisateurs/${id}`, user);
  return response.data;
};

export const deleteUser = async (id) => {
  await axios.delete(`${API_URL}/utilisateurs/${id}`);
};

import React from 'react';
import { Box, Typography } from '@mui/material';

const DashboardPage = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4">Dashboard</Typography>
      <Typography>Bienvenue sur le tableau de bord.</Typography>
    </Box>
  );
};

export default DashboardPage;

import axios from 'axios';

const API_URL = 'https://admin-gg-be.visamonde.com';

export const getAdressesFacturation = async (idSociete) => {
  const response = await axios.get(`${API_URL}/adresses_facturation/${idSociete}`);
  return response.data;
};

export const createAdresseFacturation = async (adresse) => {
  const response = await axios.post(`${API_URL}/adresses_facturation`, adresse);
  return response.data;
};

export const updateAdresseFacturation = async (id, adresse) => {
  const response = await axios.put(`${API_URL}/adresses_facturation/${id}`, adresse);
  return response.data;
};

export const deleteAdresseFacturation = async (id) => {
  const response = await axios.delete(`${API_URL}/adresses_facturation/${id}`);
  return response.data;
};

import axios from 'axios';

const API_URL = 'https://admin-gg-be.visamonde.com'; // Remplace par ton URL d'API

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { email, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

import React, { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { getUsers, createUser, updateUser, deleteUser } from '../models/user';
import { getGroups } from '../models/group';

const UtilisateursPage = () => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [newUser, setNewUser] = useState({ nom: '', email: '', mot_de_passe: '', droit: 'admin', id_groupe: '' });
  const [editUser, setEditUser] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const fetchUsers = useCallback(async () => {
    const data = await getUsers(user.id_societe);
    setUsers(data);
  }, [user.id_societe]);

  const fetchGroups = useCallback(async () => {
    const data = await getGroups();
    setGroups(data);
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchGroups();
  }, [fetchUsers, fetchGroups]);

  const handleCreate = async () => {
    const userToCreate = { ...newUser, id_societe: user.id_societe };
    if (userToCreate.id_groupe === '') {
      userToCreate.id_groupe = null;
    }
    await createUser(userToCreate);
    setNewUser({ nom: '', email: '', mot_de_passe: '', droit: 'admin', id_groupe: '' });
    fetchUsers();
  };

  const handleUpdate = async (id) => {
    const userToUpdate = { ...editUser, id_societe: user.id_societe };
    if (userToUpdate.id_groupe === '') {
      userToUpdate.id_groupe = null;
    }
    await updateUser(id, userToUpdate);
    setEditUser(null);
    fetchUsers();
  };

  const handleDelete = async (id) => {
    await deleteUser(id);
    fetchUsers();
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditUserChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Utilisateurs</Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextField
          label="Nom"
          name="nom"
          value={newUser.nom}
          onChange={handleNewUserChange}
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={newUser.email}
          onChange={handleNewUserChange}
          margin="normal"
        />
        <TextField
          label="Mot de passe"
          name="mot_de_passe"
          type="password"
          value={newUser.mot_de_passe}
          onChange={handleNewUserChange}
          margin="normal"
        />
        <FormControl margin="normal" style={{ width: '20%' }}>
          <InputLabel>Droit</InputLabel>
          <Select
            name="droit"
            value={newUser.droit}
            onChange={handleNewUserChange}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="superadmin">Super Admin</MenuItem>
          </Select>
        </FormControl>
        {newUser.droit === 'admin' && (
          <FormControl margin="normal" style={{ width: '20%' }}>
            <InputLabel>Groupe</InputLabel>
            <Select
              name="id_groupe"
              value={newUser.id_groupe}
              onChange={handleNewUserChange}
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button variant="contained" color="primary" onClick={handleCreate} disabled={newUser.droit === 'admin' && !newUser.id_groupe}>
          Créer
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Droit</TableCell>
              <TableCell>Groupe</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id_utilisateur}>
                <TableCell>{user.id_utilisateur}</TableCell>
                <TableCell>
                  {editUser && editUser.id_utilisateur === user.id_utilisateur ? (
                    <TextField
                      name="nom"
                      value={editUser.nom}
                      onChange={handleEditUserChange}
                    />
                  ) : (
                    user.nom
                  )}
                </TableCell>
                <TableCell>
                  {editUser && editUser.id_utilisateur === user.id_utilisateur ? (
                    <TextField
                      name="email"
                      value={editUser.email}
                      onChange={handleEditUserChange}
                    />
                  ) : (
                    user.email
                  )}
                </TableCell>
                <TableCell>
                  {editUser && editUser.id_utilisateur === user.id_utilisateur ? (
                    <FormControl margin="normal" fullWidth>
                      <Select
                        name="droit"
                        value={editUser.droit}
                        onChange={handleEditUserChange}
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="superadmin">Super Admin</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    user.droit
                  )}
                </TableCell>
                <TableCell>
                  {user.nom_groupe || ''}
                  {editUser && editUser.id_utilisateur === user.id_utilisateur && editUser.droit === 'admin' && (
                    <FormControl margin="normal" fullWidth>
                      <InputLabel>Groupe</InputLabel>
                      <Select
                        name="id_groupe"
                        value={editUser.id_groupe}
                        onChange={handleEditUserChange}
                      >
                        {groups.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {group.nom}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
                <TableCell>
                  {editUser && editUser.id_utilisateur === user.id_utilisateur ? (
                    <Button onClick={() => handleUpdate(user.id_utilisateur)}>Enregistrer</Button>
                  ) : (
                    <Button onClick={() => setEditUser(user)}>Modifier</Button>
                  )}
                  <Button onClick={() => handleDelete(user.id_utilisateur)}>Supprimer</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default UtilisateursPage;

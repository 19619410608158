import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';

const Header = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  if (!user) {
    navigate('/login');
   return null;
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Bonjour, {user.nom} de {user.societe}
        </Typography>
        {user.droit === 'superadmin' && (
          <Box sx={{ display: 'flex' }}>
            <Button color="inherit" onClick={() => navigate('/demandes')}>
              Demandes
            </Button>
            <Button color="inherit" onClick={() => navigate('/groupes')}>
              Groupes
            </Button>
            <Button color="inherit" onClick={() => navigate('/utilisateurs')}>
              Utilisateurs
            </Button>
            <Button color="inherit" onClick={() => navigate('/adresses')}>
              Adresses de Facturation
            </Button>
          </Box>
        )}
        {user.droit !== 'superadmin' && (
          <Box sx={{ display: 'flex' }}>
            <Button color="inherit" onClick={() => navigate('/demandes')}>
              Demandes
            </Button>
          </Box>
        )}
        <Button color="inherit" onClick={() => {
          localStorage.removeItem('user');
          navigate('/login');
        }}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
